#layout-menu .logo {
  float: left;
  width: 120px;
  height: 50px;
  margin: 16px 24px 16px -16px;
  background-image: url('/public/logo.png'); 
  background-size: contain; 
  background-repeat: no-repeat;
} 

.ant-row-rtl #layout-menu .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
} 

.clickableRow { 
  cursor: pointer;
}

.pointer { 
  cursor: pointer;
}